import { commonEnvironment } from './environment.common';

export const environment = {
  ...commonEnvironment,
  production: true,
  sentry: 'https://0366305a84b94477945482bd19c939bb@o296516.ingest.sentry.io/5898911',
  apiUrl: 'https://app.sutterbau.ch',
  auth: {
    serverUrl: 'https://auth.cloud.triarc-labs.com/auth/',
    clientId: 'sutter',
    bearerExcludedUrls: ['http://localhost:4200'],
    scope: 'openid email profile offline_access',
  },
};
