import { Theme } from './theme';

export const commonEnvironment = {
  activatedRealms: [],
  theme: {
    logo: 'assets/logos/logo_sutter_big.png',
    largeLogo: 'assets/logos/logo_sutter_big.png',
    cssProperties: {
      'header-background': '#8c0d04',
      'header-background-image': 'none',
      'ion-color-primary': '#666',
      'background-toolbar': '#222222',
      'logo-height': '100px',
      'logo-width': '80px',
      'bg-light': '#dcd504',
      'bg-dark': '#dcd504',
      'background-secondary-green': '222222',
      'background-secondary-green-gradient':
        'linear-gradient(90deg, #222222 5%, #222222 30%, #222222 70%, #222222 95%)',
    },
  } as Theme,
  showTimeDifferenceOverview: true,
  production: false,
  version: '0.0.0',
  updatePollingInterval: 60,
  updatePollingIntervalDelay: 10,
  commitHash: '',
  buildDate: '',
  versionUrl: 'https://gitlab.com/triarc-labs/sutter/sutterapp/-/network',
  sentry: '',
  apiUrl: 'http://localhost:5000',
  passwordChangeUrl:
    '/protocol/openid-connect/auth?client_id=sutter&response_type=code&scope=openid&kc_action=UPDATE_PASSWORD',
  auth: {
    serverUrl: 'http://localhost:8080/auth/',
    clientId: 'sutter',
    scope: 'openid email profile offline_access',
    bearerExcludedUrls: ['http://localhost:4200'],
  },
  features: {
    reportTab: true,
    documentTab: true,
  },
};
